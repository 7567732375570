// auth.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ILoginAPIResponse, IEmailLoginRequest, IUserAPIResponse, ILogoutRequest, IEmailSignupRequest, IEmailSignupResponse, ILoginAPIErrorResponse, IVerifyEmailRequest, IVerifyEmailResponse, IResendEmailVerificationRequest, IUserProfileAPIRequest, IMasterCountryResponse, IMasterTimeZoneResponse, IProfileUpdateAPIResponse, IVerifyPhoneNoResponse, IVerifyPhoneNoRequest } from './store/auth.models';
import { env } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAPIErrorResponse, IAPIResponse } from '../../models/base';


@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private authAPIUrl = `${env.apiBaseUrl}/users-service/auth/root`;
    private profileAPIUrl = `${env.apiBaseUrl}/users-service/profile/root`;
    items$!: Observable<any>
    private isProfileUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isProfileUpdated$ = this.isProfileUpdated.asObservable();


    constructor(private http: HttpClient) {
    }

    login(credentials: IEmailLoginRequest): Observable<ILoginAPIResponse | ILoginAPIErrorResponse> {
        const loginUrl = `${this.authAPIUrl}/login`;
        return this.http.post<ILoginAPIResponse>(loginUrl, credentials).pipe(

            // handle error
            catchError(error => {
                return of(error.error as ILoginAPIErrorResponse)
            })
        );
    }

    signup(credentials: IEmailSignupRequest): Observable<IEmailSignupResponse | IAPIErrorResponse> {
        const signupUrl = `${this.authAPIUrl}/register`;
        return this.http.post<IEmailSignupResponse>(signupUrl, credentials).pipe(
            catchError(error => of(error.error as IAPIErrorResponse))
        );
    }

    verifyEmail(credentials: IVerifyEmailRequest): Observable<IVerifyEmailResponse | IAPIErrorResponse> {
        const verifyEmailUrl = `${this.authAPIUrl}/verifyemail`;
        return this.http.post<IVerifyEmailResponse>(verifyEmailUrl, credentials).pipe(
            catchError(error => of(error.error as IAPIErrorResponse))
        );
    }

    resendVerifyEmail(credentials: IResendEmailVerificationRequest): Observable<IVerifyEmailResponse | IAPIErrorResponse> {
        const resendEmailVerificationUrl = `${this.authAPIUrl}/resendverificationemail`;
        return this.http.post<IVerifyEmailResponse>(resendEmailVerificationUrl, credentials).pipe(
            catchError(error => of(error.error as IAPIErrorResponse))
        );
    }

    getMe(): Observable<IUserAPIResponse | IAPIErrorResponse> {
        const url = `${this.profileAPIUrl}/load`;
        return this.http.get<IUserAPIResponse>(url).pipe(
            // handle error
            catchError(error => {
                throw new Error('Invalid credentials');
            })
        );
    }
    
    updateMe( credentials: IUserProfileAPIRequest ): Observable<IProfileUpdateAPIResponse | IAPIErrorResponse> {
        const url = `${this.profileAPIUrl}/update`;
        return this.http.patch<IProfileUpdateAPIResponse>(url, credentials).pipe(
            // handle error
            catchError(error => of(error.error as IAPIErrorResponse))
        );
    }

    logout(credentials: ILogoutRequest): Observable<IAPIResponse<null> | IAPIErrorResponse> {
        const url = `${this.authAPIUrl}/logout`;
        return this.http.post<IAPIResponse<null>>(url, credentials)
    }

    loadCountries(): Observable<IMasterCountryResponse | IAPIErrorResponse>{
        const url = `${env.apiBaseUrl}/users-service/master/countries`
        return this.http.get<IMasterCountryResponse>(url).pipe(
            catchError(error => {
                throw new Error('Invalid credentials');
            })
        )
    }
    
    loadTimezones(): Observable<IMasterTimeZoneResponse | IAPIErrorResponse>{
        const url = `${env.apiBaseUrl}/users-service/master/timezones`
        return this.http.get<IMasterTimeZoneResponse>(url).pipe(
            catchError(error => {
                throw new Error('Invalid credentials');
            })
        )
    }
    
    markAsOnboarded(): Observable<any | IAPIErrorResponse>{
        const url = `${this.profileAPIUrl}/markasonboarded`
        return this.http.patch<any>(url, null).pipe(
            catchError(error => {
                throw new Error('Invalid credentials');
            })
        )
    }

    setProfileasUpdated(status: boolean) {
        this.isProfileUpdated.next(status);
    }

    verifyPhoneNo(credentials: IVerifyPhoneNoRequest): Observable<IVerifyPhoneNoResponse | IAPIErrorResponse>{
        const url = `${this.profileAPIUrl}/verifyphoneno`
        return this.http.put<IVerifyPhoneNoResponse>(url, credentials).pipe(
            catchError(error => of(error.error as IAPIErrorResponse))
        )
    }

    resendPhoneVerification(): Observable<IVerifyPhoneNoResponse | IAPIErrorResponse> {
        const url = `${this.profileAPIUrl}/resendphonenootp`;
        return this.http.get<IVerifyPhoneNoResponse>(url).pipe(
            catchError(error => of(error.error as IAPIErrorResponse))
        );
    }
}

// Add other authentication-related methods as needed (e.g., logout, registration)

